
.language-container {
  color: white;
  display: flex;
  position: fixed;
  height: auto;
  top: 0px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
  width: auto;
  transition: transform 0.3s ease-in-out;
  flex-shrink: 1;
}