.header {
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: space-between;
    z-index: 100;
}

.header:hover{
    cursor: pointer;
}

.logo {
    width: 8vh;
    margin: 1vh 2vw;
    transition: transform 0.3s ease-in-out;
    position: fixed;
    z-index: 1000;
    border-radius: 13px;
}

@media screen and (min-width: 330px) and (max-width: 480px) {
.logo {
    width: 8vh;
}
}


