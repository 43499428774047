.marquee-container {
  position: relative;
  width: 100%;
  height: 450px; 
}


.marquee-div {
  position: relative;
  z-index: 2;
  display: block;
  top: 60px;
  padding: 0px;
  font: normal 96px / 160px NeuePlak-Black, -apple-system, BlinkMacSystemFont, Lato, Segoe UI, Verdana, Arial, sans-serif;
  color: transparent;
  -webkit-text-stroke: 1px rgb(255, 255, 255);
  font-weight: 800;
  cursor: default;
  transition: color 0.3s ease, transform 0.3s ease;
}


.marquee-item {
  padding-right: 20px; 
}

@media screen and (max-width: 764px) {
  .marquee-div {
    font: normal 72px / 140px NeuePlak-Black, -apple-system, BlinkMacSystemFont, Lato, Segoe UI, Verdana, Arial, sans-serif;
  }

  .marquee-container {
    height: 330px;
}
}