.Banner {
    position: relative; 
    height: 100vh; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1a1a1a;
    overflow: hidden;
}

.titleHome {
    font-size: 20vh;
    color: white;
    z-index: 1;
}

.midlogo {
    width: 850px;
    height: auto;
}

.video-banner {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0% 110%;
    position: absolute;
}

.video-banner video#bgvid {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    filter: blur(10px);
}


.BannerText{
    margin-top: 30px;
    color: rgba(255, 255, 255, 0.625) !important;
    font-size: 4.0rem;
}

.BannerText:hover{
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
    .midlogo {
        width: 500px;
    }

    .BannerText{
        font-size: 3.5rem;
    }
}

@media screen and (max-width: 768px) {
    .midlogo {
        width: 400px;
    }
    

    
}

@media screen and (max-width: 480px) {
    .midlogo {
        width: 300px;
    }
    
    .BannerText{
        font-size: 3.5rem;
        position: absolute;
        top: 70%;
    }
}