@font-face {
  font-family: "ClashGrotesk";
  src: url("Css/Fonts/ClashGrotesk-Extralight.otf");
  src: url("Css/Fonts/ClashGrotesk-Variable.ttf");
  font-weight: normal;
  font-style: normal;
}

.App {
  background-color: rgb(0, 0, 0);
  min-height: 100vh;
}

