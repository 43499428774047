.page {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100%;
    background: url("thomaslight2.jpg"), #000;
    background-position: center;
    background-size: 600px;
    background-repeat: no-repeat;
    background-position: top -350px left 30%;
    gap: 15px;
    padding: 0 0px;
}

/* LEFT-SIDE */

#left {
    position: relative;
    height: 40%;
}

#left .wrapper {
    position: sticky;
    top: 50%;
    left: 0;
}
#left .networks {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 50px;
    position: relative;
    left: -20%;
}
#left hr {
    margin: 0;
}

#left .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

#left h1 {
    color: white;
    font-size: 2.0em;
    margin-bottom: 0px;
    font-weight: 900;
    letter-spacing: 2px;
    font-family: "ClashGrotesk", sans-serif;
}

.Social-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-size: 11px;
    margin: 15px 0;
}

.Social-icons a {
    color: #ffffff;
    transition: color 0.3s;
}

.Social-icons a:hover {
    color: hsl(0, 100%, 50%);
}

.Social-footer-pc {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 15px;
}

.Social-footer-pc a {
    color: rgb(255, 255, 255);
    transition: color 0.3s;
}

.Social-footer-pc a:hover {
    color: hsl(0, 100%, 50%);
}

.Content-button {
    position: relative;
    overflow: hidden;
    border: 1px solid #ff0000;
    color: #ffffff;
    display: inline-block;
    font-size: 20px;
    font-weight: 100;
    line-height: 10px;
    width: 150px;
    padding: 20px 20px 20px;
    text-decoration: none;
    cursor: pointer;
    background: #000000;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.Content-button span {
    color: #ffffff;
}

.Content-button span:first-child {
    position: relative;
    transition: color 600ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 10;
}

.Content-button span:last-child {
    display: block;
    position: absolute;
    bottom: 0;
    transition: all 500ms cubic-bezier(0.48, 0, 0.12, 1);
    z-index: 100;
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateY(225%) translateX(-50%);
    height: 14px;
    line-height: 13px;
}

.Content-button:after {
    content: "";
    position: absolute;
    bottom: -50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 0, 0);
    transform-origin: bottom center;
    transition: transform 600ms cubic-bezier(0.48, 0, 0.12, 1);
    transform: skewY(9.3deg) scaleY(0);
    z-index: 50;
}

.Content-button:hover:after {
    transform-origin: bottom center;
    transform: skewY(9.3deg) scaleY(2);
}

.Content-button:hover span:last-child {
    transform: translateX(-50%) translateY(-50%);
    opacity: 1;
    transition: all 900ms cubic-bezier(0.48, 0, 0.12, 1);
}



/* RIGHT SIDE */

#right {
    display: flex;
    position: static;
    height: 100%;
    align-items: center;
    flex-direction: column;
    margin-right: 1%;
}


.Presskit-sep {
    width: 35vh;
    height: 1px;
    border: none;
    margin-top: -15px;
    background-color: white;
}

.middle-section {
    height: auto;
    width: auto;
}

.Presskit-hr {
    width: 1px;
    height: 15vh;
    border: none;
    background-color: white;
}



.presskit-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    gap: 15px;
}

.Content-presskit {
    color: white;
    font-size: 2.8em;
    letter-spacing: 3px;
    font-family: "ClashGrotesk", sans-serif;
}

.Content-textbox {
    color: white;
    font-size: 20px;
    font-family: "ClashGrotesk", sans-serif;
    max-width: 95%;
    position: sticky;
}

.div-social-tel {
    display: none;
}

.Social-footer-tel {
    display: none;
}

/* PRESS KIT */

.presskit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.Content-presskit{
    margin: 50px 0px 0px 0px;
}

#right hr{
    color: #ffffff;
    width: 300px;

}

.Presskit-button {
    appearance: none;
    background-color: #000000;
    border-width: 0;
    box-sizing: border-box;
    color: #ffffff !important;
    cursor: pointer;
    font-family: Clarkson, Helvetica, sans-serif;
    position: relative;
    border: 1px solid #ff0000;
    outline: 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: opacity 300ms cubic-bezier(.694, 0, 0.335, 1), background-color 100ms cubic-bezier(.694, 0, 0.335, 1), color 100ms cubic-bezier(.694, 0, 0.335, 1);
    white-space: nowrap;
    margin-bottom: 50px;
}

.Presskit-button:before {
    animation: opacityFallbackOut .5s step-end forwards;
    backface-visibility: hidden;
    background-color: #ff0000;
    clip-path: polygon(-1% 0, 0 0, -25% 100%, -1% 100%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateZ(0);
    transition: clip-path .5s cubic-bezier(.165, 0.84, 0.44, 1), -webkit-clip-path .5s cubic-bezier(.165, 0.84, 0.44, 1);
    width: 100%;
}

.Presskit-button:hover:before {
    animation: opacityFallbackIn 0s step-start forwards;
    clip-path: polygon(0 0, 101% 0, 101% 101%, 0 101%);
}

.Presskit-button:after {
    background-color: #FFFFFF;
}

.Presskit-button span {
    z-index: 1;
    position: relative;
}

/* MEDIA QUERIES */

@media screen and (max-width: 1300px) {
    .Content-button{
        display: flex;
    }
    .page {
     grid-template-columns: 1fr;
     grid-template-rows: auto;
     background-attachment: fixed;
     background-size: 900px;

    }   

    #left hr{
        display: none;
    }

    #left .wrapper {
        position: static;
    }
    #left .networks {
        left: 0;
    }

    .Content-textbox{
        max-width: 95%;
    }

    .Social-footer-pc {
        display: none;
    }

    .div-social-tel {
        display:none;
    }

    .Social-footer-tel {
        display: none;
    }

 }

@media screen and (min-width: 330px) and (max-width: 500px) {
    .page{
        grid-template-rows: auto;
        background-attachment: fixed;
        background-size: 600px;
        background-position: top 0px left 50%;

    }
    
    #left h1{
        display: none;
    }

    #left .Social-icons {
        display: none;
    }

    #left hr{
        display: none;
    }

    .Content-button {
        font-size: 20px;
        padding: 20px 20px 20px !important;
    }

    .Social-footer-pc {
        display: none;
    }

    .presskit-section {
        flex-direction: column;
        width: 60%;
    }

    #left .wrapper {
        position: static;
        top: 50%;
        left: 0;
    }

    #left .networks {
        left: 0;
    }


    .Content-textbox {
        font-size: 17px;
        max-width: 90%;
    }

    .div-social-tel {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    .Social-footer-tel {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        position: relative;
    }

    .div-social-tel a {
        color: rgb(255, 255, 255);
        transition: color 0.3s;
    }

    .div-social-tel a:hover {
        color: hsl(0, 100%, 50%);
    }

    .presskit-container{
        margin-bottom: 25px;
    }

    .Presskit-button {
        background-color: transparent;
        margin-bottom: 0px;
    }
}


/*
@media screen and (min-width: 330px) and (max-width: 480px) {
    .content {
        display: none;
    }

    .content-title {
        display: none;
    }

    .Social-icons {
        display: none;
    }

    .Presskit-button {
        background-color: transparent;
    }

    .Content-presskit {
        font-size: 2em !important;
    }

    .content-title {
        font-size: 2em !important;
    }

    .presskit-container {
        margin-bottom: 30px;
    }


    .Social-footer-pc {
        width: auto;
        gap: 25px;
        display: none;
    }

    .div-social-tel {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        top: 30%;
    }

    .Social-footer-tel {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 50px;
        position: relative;
    }

    .div-social-tel a {
        color: rgb(255, 255, 255);
        transition: color 0.3s;
    }

    .div-social-tel a:hover {
        color: hsl(0, 100%, 50%);
    }


    .fa-3x {
        font-size: 2em;
    }

}
/*
@media screen and (max-width: 1024px) {
    .Content {
        background-position: left -200px bottom 0px;
        height: auto;
    }

    /* .content-wrapper {
            flex-direction: column;
            justify-content: center;
        } * /

    .left-section {
        position: relative;
        top: 0;
        flex-direction: column;
        gap: 10px;
    }

    .Content-elements {
        align-items: center;
    }

    .content-title {
        font-size: 3em;
    }

    .Social-icons {
        gap: 10px;
    }

    .Content-button {
        font-size: 25px;
        padding: 20px 20px 20px !important;
    }

    .Presskit-button {
        font-size: 25px;
        padding: 20px 20px 20px !important;
    }

    .bar-section {
        flex-direction: column;
        gap: 10px;
    }


    .left-side {
        width: 100%;
        height: auto;
    }

    .Presskit-sep {
        width: 24vh;
        height: 1px;
    }

    .middle-section {
        display: none;
    }

    .Presskit-hr {
        width: 15vh;
        height: 1px;
    }

    .right-side {
        width: 100%;
        gap: 50px;
        margin-right: unset;
        margin: auto;
    }

    .presskit-section {
        gap: 20px;
        flex-direction: column;
    }

    .Content-presskit {
        font-size: 3em;
    }

    .Content-textbox {
        font-size: 18px;
        max-width: 80%;
    }

    .presskit-container {
        gap: 10px;
        width: 100%;
        margin-right: unset;
    }

    .div-social-pc {
        gap: 5px;
        margin-right: unset;
    }

    .Social-footer {
        gap: 20px;
    }

    .Content-Follow {
        font-size: 3em;
    }
} */